@import "../imports";

.section-about-list {
    position: relative;

    .section {
        &__list {
            display: flex;
            flex-wrap: wrap;
            counter-reset: list-counter;
            padding: sizer(105) sizer(160) sizer(105) 0;

            @include respond-to("-screen-xxxl") {
                padding: sizer(95) sizer(145) sizer(95) 0;
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(85) sizer(125) sizer(85) 0;
            }

            @include respond-to("-screen-xl") {
                padding: sizer(70) sizer(105) sizer(75) 0;
            }

            @include respond-to("-screen-lg") {
                padding: sizer(55) sizer(90) sizer(55) 0;
            }

            @include respond-to("-screen-md") {
                padding: sizer(30) sizer(30) sizer(50) 0;
            }

            @include respond-to("-screen-sm") {
                padding: sizer(20) sizer(20) sizer(40) 0;
            }

            .list {
                &__item {
                    width: 33.3333%;
                    flex: 0 0 33.3333%;
                    counter-increment: list-counter;
                    padding: 0 0 0 sizer(160);

                    @include respond-to("-screen-xxxl") {
                        padding: 0 0 0 sizer(145);
                    }

                    @include respond-to("-screen-xxl") {
                        padding: 0 0 0 sizer(110);
                    }

                    @include respond-to("-screen-xl") {
                        padding: 0 0 0 sizer(70);
                    }

                    @include respond-to("-screen-lg") {
                        padding: 0 0 0 sizer(50);
                    }

                    @include respond-to("-screen-md") {
                        width: 50%;
                        flex: 0 0 50%;
                        padding-top: sizer(40);
                    }

                    @include respond-to("-screen-sm") {
                        padding: sizer(30) 0 0 sizer(30);
                    }

                    @include respond-to("-screen-xs") {
                        width: 100%;
                        flex: 0 0 100%;
                        padding: sizer(20);
                    }

                    &::before {
                        content: counter(list-counter, decimal-leading-zero);
                        display: block;
                        font-size: text-sizer(36);
                        font-weight: $weight__bold;
                        color: $color__psi;
                        letter-spacing: sizer(-0.025);

                        @include respond-to("-screen-xl") {
                            font-size: text-sizer(32);
                        }

                        @include respond-to("-screen-lg") {
                            font-size: text-sizer(29);
                        }

                        @include respond-to("-screen-md") {
                            font-size: text-sizer(26);
                        }

                        @include respond-to("-screen-sm") {
                            font-size: text-sizer(23);
                        }
                    }
                }
            }
        }
    }
}
