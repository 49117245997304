.loader {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    transition: opacity 0.3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        width: sizer(50);
        display: block;
        height: sizer(50);
        border-radius: 50%;
        border: sizer(3) solid #249dd7;
        border-right: none;
        animation: spin 0.5s ease-in-out;
    }

    @keyframes spin {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    &.hidden {
        z-index: -100;
        opacity: 0;
    }
}
