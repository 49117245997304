@import "../imports";

.section-offer-single {
    position: relative;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%);
    }

    &::before {
        content: "";
        box-shadow: -4.596px 3.857px 38px 0px rgba(0, 0, 0, 0.1);
        width: 70%;
        transform: translate(-50%);
        z-index: 1;
        height: 50%;
    }

    &::after {
        z-index: 2;
        width: 100%;
        background: $color__omega;
        height: 100%;
    }

    .section {
        &__container {
            padding: sizer(85) sizer(100) sizer(95);
            position: relative;
            z-index: 5;

            @include respond-to("-screen-xxxl") {
                padding: sizer(70) sizer(70) sizer(75);
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(70) sizer(40) sizer(75);
            }

            @include respond-to("-screen-xl") {
                padding: sizer(60) sizer(30) sizer(65);
            }

            @include respond-to("-screen-lg") {
                padding: sizer(40) 0 sizer(45);
            }
        }

        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 3;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__row {
            display: flex;
            justify-content: space-between;
        }

        &__col {
            padding: sizer(20) 0 sizer(40) 0;

            @include respond-to("-screen-xl") {
                padding: sizer(20) 0 sizer(20) 0;
            }

            &--nav {
                width: 33.333%;
                flex: 0 0 33.333%;
                border-right: sizer(1) solid rgba($color__psi, 0.3);

                @include respond-to("-screen-lg") {
                    width: 40%;
                    flex: 0 0 40%;
                }

                .nav {
                    &__list {
                        display: flex;
                        flex-direction: column;
                    }

                    &__item {
                        margin: 0 0 sizer(13);
                        display: flex;
                        width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &__link {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        border: none;
                        background: transparent;
                        transition: all 0.3s ease-out;
                        cursor: pointer;
                        position: relative;
                        z-index: 2;
                        text-align: left;
                        padding: sizer(5) sizer(70) sizer(5) 0;

                        @include respond-to("-screen-xl") {
                            padding: sizer(5) sizer(40) sizer(5) 0;
                        }

                        @include respond-to("-screen-lg") {
                            padding: sizer(5) sizer(25) sizer(5) 0;
                        }

                        &::before {
                            content: "";
                            width: 150%;
                            height: 100%;
                            right: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            background: linear-gradient(
                                to right,
                                rgba($color__beta, 0.0000001),
                                rgba($color__beta, 0.1)
                            );
                            position: absolute;
                            z-index: -1;
                            opacity: 0;
                            transition: all 0.3s ease-out;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: $color__beta;

                            &::before {
                                opacity: 1;
                            }
                        }

                        &.isActive {
                            .text {
                                font-weight: $weight__bold !important;
                            }

                            &::before {
                                opacity: 1;
                            }
                        }

                        .icon {
                            margin-right: sizer(35);

                            img {
                                width: sizer(35);
                                filter: brightness(0) saturate(100%) invert(63%) sepia(24%) saturate(6967%)
                                    hue-rotate(168deg) brightness(91%) contrast(84%);
                            }
                        }
                    }
                }
            }

            &--content {
                width: 58.33%;
                flex: 0 0 58.33%;
                padding-right: 5%;

                @include respond-to("-screen-lg") {
                    width: 55%;
                    flex: 0 0 55%;
                    padding-right: sizer(30);
                }
            }
        }

        &__col--nav,
        &__header {
            padding-left: sizer(60);
        }

        &__content {
            .content {
                &__item {
                    .item {
                        &__header {
                            display: flex;
                            flex-direction: column;

                            &::after {
                                content: "";
                                display: block;
                                width: sizer(75);
                                height: sizer(6);
                                background: $gradient__beta;
                                margin: sizer(20) 0 sizer(40);
                            }
                        }
                    }
                }
            }
        }
    }
}
