@import "../imports";

.section-innovations {
    .web-header__title {
        @include respond-to("-screen-xl") {
            position: relative;
            z-index: 20;
            margin: sizer(20);
            margin-left: sizer(50);

            &::before {
                content: none;
            }
        }

        @include respond-to("-screen-lg") {
            margin-left: sizer(40);
        }

        @include respond-to("-screen-md") {
            margin-left: sizer(30);
        }

        @include respond-to("-screen-sm") {
            margin-left: sizer(20);
        }
    }

    .section {
        &__container {
            display: flex;

            @include respond-to("-screen-xl") {
                display: block;
            }
        }

        &__wrapper {
            position: relative;
            overflow: hidden;
        }

        &__content,
        &__header {
            position: relative;
            z-index: 3;
        }

        &__header {
            z-index: 10;
        }

        &__content {
            width: 90vw;

            @include respond-to("screen-xl") {
                min-width: sizer(1480);
            }
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: sizer(30) sizer(190) sizer(110) sizer(150);

            @include respond-to("-screen-xl") {
                width: 100%;
                padding: sizer(30) sizer(50) sizer(50) sizer(50);

                & > div {
                    overflow: visible !important;
                    max-height: none !important;
                    margin-right: 0 !important;
                    margin-bottom: 0 !important;
                    min-height: 0 !important;

                    & > div {
                        overflow: visible !important;
                        max-height: none !important;
                        margin-right: 0 !important;
                        margin-bottom: 0 !important;
                        min-height: 0 !important;
                    }
                }
            }

            @include respond-to("-screen-lg") {
                padding: sizer(40) sizer(25) sizer(25) sizer(40);
            }

            @include respond-to("-screen-md") {
                padding: sizer(30) sizer(20) sizer(20) sizer(30);
            }

            @include respond-to("-screen-sm") {
                padding: sizer(20) sizer(20) sizer(20) sizer(20);
            }
        }

        &__list {
            @include respond-to("screen-xl") {
                height: 100%;
                width: 100%;

                display: grid;
                grid-template-columns: 1fr;
                grid-auto-flow: row;
                grid-template-rows: 33% 33% 33%;
            }

            .list {
                &__item {
                    @include respond-to("screen-xl") {
                        height: 100%;
                        width: 100%;
                        position: relative;
                    }

                    .item {
                        &__wrapper {
                            position: relative;

                            @include respond-to("screen-xl") {
                                height: 100%;

                                width: 100%;
                            }
                            z-index: 2;

                            &:hover,
                            &:focus,
                            &:active,
                            &:focus-within {
                                &::before,
                                &::after {
                                    opacity: 1;
                                }

                                .item {
                                    &__link {
                                        &::before {
                                            opacity: 1;
                                        }
                                    }

                                    &__more {
                                        opacity: 1;
                                    }
                                }
                            }

                            &::before,
                            &::after {
                                content: "";
                                position: absolute;
                                left: 50%;
                                transform: translate(-50%, 0%);
                                width: 70%;
                                height: 50%;
                                z-index: 1;
                                opacity: 0;
                                transition: all 0.3s ease-out;
                            }

                            &::before {
                                box-shadow: 0 sizer(-16) sizer(38) sizer(-16) rgba(0, 0, 0, 0.1) !important;
                                top: 0;
                            }

                            &::after {
                                box-shadow: 0 sizer(16) sizer(38) sizer(-16) rgba(0, 0, 0, 0.1) !important;
                                bottom: 0;
                            }
                        }

                        &__link {
                            display: flex;
                            align-items: center;
                            padding: sizer(5) 0;
                            position: relative;
                            z-index: 3;

                            @include respond-to("screen-xl") {
                                height: 100%;
                                width: 100%;
                            }

                            @include respond-to("-screen-xl") {
                                padding: sizer(10) 0;
                            }

                            @include respond-to("-screen-xs") {
                                flex-direction: column-reverse;
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background: $color__omega;
                                z-index: 2;
                                opacity: 0;
                                transition: all 0.3s ease-out;
                            }
                        }

                        &__image,
                        &__content {
                            position: relative;
                            z-index: 4;
                        }

                        &__subtitle {
                            margin: sizer(-9) 0 0;
                        }

                        &__image {
                            // width: 60%;
                            @include respond-to("screen-xl") {
                                padding-left: 26%;
                                height: 100%;
                                display: block;
                                position: relative;
                            }

                            img {
                                @include respond-to("screen-xl") {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }

                                // width: sizer(350);

                                // @include respond-to("-screen-xxxl") {
                                //     width: sizer(300);
                                // }

                                // @include respond-to("-screen-xxl") {
                                //     width: sizer(280);
                                // }

                                @include respond-to("-screen-xl") {
                                    width: sizer(250);
                                }

                                @include respond-to("-screen-lg") {
                                    width: sizer(225);
                                }

                                @include respond-to("-screen-md") {
                                    width: sizer(170);
                                }

                                @include respond-to("-screen-sm") {
                                    width: sizer(130);
                                }

                                @include respond-to("-screen-xs") {
                                    width: sizer(180);
                                    margin-top: sizer(15);
                                }
                            }
                        }

                        &__header {
                            margin: 0 0 sizer(15);

                            @include respond-to("-screen-md") {
                                margin-bottom: sizer(10);
                            }
                        }

                        &__content {
                            display: flex;
                            align-items: flex-end;

                            @include respond-to("-screen-sm") {
                                flex-wrap: wrap;
                            }
                        }

                        &__more {
                            line-height: 2.1em;
                            margin: 0 0 0 sizer(70);
                            opacity: 0;
                            transition: all 0.3s ease-out;

                            @include respond-to("-screen-lg") {
                                margin: 0 0 0 sizer(30);
                            }

                            @include respond-to("-screen-md") {
                                margin: 0 0 0 sizer(15);
                            }

                            @include respond-to("-screen-sm") {
                                display: block;
                                width: 100%;
                                margin-left: 0;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}
