// For SCSS functions

// Function to calculate distance
@function sizer($multipler) {
  $result: calc(var(--bw__sizer) * #{$multipler});
  @return $result;
}

@function text-sizer($multipler) {
  $multiplied: $multipler / 16;
  $result: calc(var(--bw__text-sizer) * #{$multiplied});
  @return $result;
}



/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
    $shadow: ();
    $from: $stroke*-1;
    @for $i from $from through $stroke {
     @for $j from $from through $stroke {
        $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
      }
    }
    @return $shadow;
  }