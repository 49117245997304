//   __   __   ___      ___  ___  __      __
//  /  ` |__) |__   /\   |  |__  |  \    |__) \ /
//  \__, |  \ |___ /~~\  |  |___ |__/    |__)  |

// ** Bartłomiej Jedlikowski **
// LinkedIn: https://www.linkedin.com/in/jedlibartlomiej/
// Gmail: jedlikowskib@gmail.com
// -------------------------------------------------------------------

// Bootstrap extend for custom screen sizes
// Generate bootstrap classes with breakpoints for screen sizes defined in abstracts/variables.scss

// Z index
.z-index {
    @each $name, $type in $breakpoints-bootstrap-default {
        @include respond-to("#{$type}-#{$name}") {
            $elements: 5;
            @for $i from -1 through $elements {
                &-#{$name}-#{$i} {
                    z-index: $i;
                }
            }
        }
    }
}

// Columns
// Generate styles for every col
.col {
    @each $name, $type in $breakpoints-bootstrap {
        $elements: 12;
        $value: 8.33333333333;

        @include respond-to("#{$type}-#{$name}") {
            @for $i from 1 through $elements {
                &-#{$name}-#{$i} {
                    flex: 0 0 ($value * $i) * 1%;
                    max-width: ($value * $i) * 1%;
                }
            }
        }
    }
}

// Generate one big class with common col styles
$common-col-styles: "";
@each $name, $type in $breakpoints-bootstrap {
    $elements: 12;
    $value: 8.33333333333;

    @for $i from 1 through $elements {
        // Update common col styles list
        $common-col-styles: $common-col-styles + ".col-#{$name}-#{$i}" + ",";
    }
}

#{$common-col-styles} {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

// Offset left
.offset {
    @each $name, $type in $breakpoints-bootstrap {
        $elements: 12;
        $value: 8.33333333333;

        @include respond-to("#{$type}-#{$name}") {
            @for $i from 1 through $elements {
                &-#{$name}-#{$i} {
                    margin-left: ($value * $i) * 1%;
                }
            }
        }
    }
}

// Offset right
.offset-right {
    @each $name, $type in $breakpoints-bootstrap-default {
        $elements: 12;
        $value: 8.33333333333;

        @include respond-to("#{$type}-#{$name}") {
            @for $i from 1 through $elements {
                &-#{$name}-#{$i} {
                    margin-right: ($value * $i) * 1%;
                }
            }
        }
    }
}

// Display
.d {
    @each $name, $type in $breakpoints-bootstrap {
        @include respond-to("#{$type}-#{$name}") {
            // Flex
            &-#{$name}-flex {
                display: flex !important;
            }

            // Block
            &-#{$name}-block {
                display: block !important;
            }

            // None
            &-#{$name}-none {
                display: none !important;
            }
        }
    }
}

// Flex positioning
.align-items {
    @each $name, $type in $breakpoints-bootstrap {
        @include respond-to("#{$type}-#{$name}") {
            // Center
            &-#{$name}-center {
                align-items: center !important;
            }

            // Start
            &-#{$name}-start {
                align-items: flex-start !important;
            }

            // End
            &-#{$name}-end {
                align-items: flex-end !important;
            }

            // Baseline
            &-#{$name}-end {
                align-items: baseline !important;
            }
        }
    }
}

.justify-content {
    @each $name, $type in $breakpoints-bootstrap {
        @include respond-to("#{$type}-#{$name}") {
            // Center
            &-#{$name}-center {
                justify-content: center !important;
            }

            // Start
            &-#{$name}-start {
                justify-content: flex-start !important;
            }

            // End
            &-#{$name}-end {
                justify-content: flex-end !important;
            }

            // Space Between
            &-#{$name}-between {
                justify-content: space-between !important;
            }
        }
    }
}

// Flex wrap
.flex {
    @each $name, $type in $breakpoints-bootstrap {
        @include respond-to("#{$type}-#{$name}") {
            // Nowrap
            &-#{$name}-nowrap {
                flex-wrap: wrap;
            }

            // Wrap
            &-#{$name}-wrap {
                flex-wrap: nowrap;
            }
        }
    }
}

// Flex direction
.flex {
    @each $name, $type in $breakpoints-bootstrap {
        @include respond-to("#{$type}-#{$name}") {
            // Column
            &-#{$name}-column {
                flex-direction: column;
            }

            // Row
            &-#{$name}-row {
                flex-direction: row;
            }
        }
    }
}

// Text
.text {
    @each $name, $type in $breakpoints-bootstrap {
        @include respond-to("#{$type}-#{$name}") {
            // Wrap
            &-#{$name}-normal {
                white-space: normal;
            }

            // Nowrap
            &-#{$name}-nowrap {
                white-space: nowrap;
            }
        }
    }
}

@include respond-to("screen-lg") {
    .text-lg-left {
        text-align: left !important;
    }
}

// Spacing
// Define default bootstrap spacer value
$spacer: 1rem;
$spacingValues: (
    "0": 0,
    "1": 0.25,
    "2": 0.5,
    "3": 1,
    "4": 1.5,
    "5": 3,
);

// Margins
.m {
    @each $name, $type in $breakpoints-bootstrap {
        @include respond-to("#{$type}-#{$name}") {
            // Top
            @each $index, $value in $spacingValues {
                &t-#{$name}-#{$index} {
                    margin-top: ($spacer * $value) !important;
                }
            }

            // Bottom
            @each $index, $value in $spacingValues {
                &b-#{$name}-#{$index} {
                    margin-bottom: ($spacer * $value) !important;
                }
            }

            // Left
            @each $index, $value in $spacingValues {
                &l-#{$name}-#{$index} {
                    margin-left: ($spacer * $value) !important;
                }
            }

            // Right
            @each $index, $value in $spacingValues {
                &r-#{$name}-#{$index} {
                    margin-right: ($spacer * $value) !important;
                }
            }
        }
    }
}

// Paddings
.p {
    @each $name, $type in $breakpoints-bootstrap {
        @include respond-to("#{$type}-#{$name}") {
            // Top
            @each $index, $value in $spacingValues {
                &t-#{$name}-#{$index} {
                    padding-top: ($spacer * $value) !important;
                }
            }

            // Bottom
            @each $index, $value in $spacingValues {
                &b-#{$name}-#{$index} {
                    padding-bottom: ($spacer * $value) !important;
                }
            }

            // Left
            @each $index, $value in $spacingValues {
                &l-#{$name}-#{$index} {
                    padding-left: ($spacer * $value) !important;
                }
            }

            // Right
            @each $index, $value in $spacingValues {
                &r-#{$name}-#{$index} {
                    padding-right: ($spacer * $value) !important;
                }
            }
        }
    }
}
