// Class for small, custom helpers

html {
    max-width: 100% !important;
    height: auto !important;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

.gsap-w__section {
    // width: 100vw;
    @include respond-to("screen-xl") {
        height: 100vh;
        overflow: hidden;
    }
}

.bg {
    &__alfa {
        background: $color__alfa;
    }
}

.image-w-100 {
    img {
        width: 100%;
    }
}

.text-center {
    text-align: center;
}

.container.container {
    &__alfa {
        max-width: sizer(1200);
    }
}

body {
    img {
        height: auto;
    }
}

.construction {
    &__wrapper {
        position: relative;
        padding: 0 0 sizer(45);
    }

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &__content {
        position: relative;
        // z-index: 3;
    }
}

.animation-container {
    width: 100%;
}

.w-100 {
    width: 100%;
}

.svg__hidden {
    position: absolute;
    visibility: hidden;
    z-index: -100;
}

.fill {
    &-gradient__alfa {
        fill: url(#gradient__alfa);
    }

    &-gradient__beta {
        fill: url(#gradient__beta);
    }
}

.text-uppercase {
    text-transform: uppercase;
}

body {
    .gsap {
        &-w {
            &__scroll-container {
                @include respond-to("-screen-md") {
                    background: $color__omega;
                }
            }
        }
    }

    &:not(.isVertical) {
        .gsap-wrapper {
            display: flex;
            flex-wrap: nowrap;
            min-width: 100vw;
            height: 100vh;
            justify-content: flex-start;
            // overflow: hidden;

            .gsap-w {
                &__scroll-container,
                &__section {
                    flex-shrink: 0;
                }

                &__bg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: $color__alfa;
                    bottom: 0;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;

                    img {
                        width: 100%;
                        mix-blend-mode: screen;
                        opacity: 0.94;
                    }
                }

                &__scroll-container {
                    display: flex;
                    justify-content: flex-start;
                    position: relative;
                    z-index: 15;
                    overflow: hidden;
                }

                &__section {
                    height: 100vh;
                    // min-width: 100vw;
                    position: relative;
                    display: flex;

                    &--test {
                        width: 100vw;
                        background: black;
                    }
                }
            }

            .wrapper {
            }
        }
    }

    &.isVertical {
        width: 100%;
        overflow: hidden;

        .gsap-wrapper {
            .gsap-w {
                &__bg {
                    display: none;
                }

                &__stars {
                    display: none;
                }

                &__bg-clouds {
                    display: none;
                }

                &__mountains {
                    display: none;
                }

                &__section {
                    width: 100%;
                    overflow: hidden;
                    position: relative;
                }
            }
        }
    }

    &.isSafari {
    }
}

.link {
    &__alfa {
        color: $color__beta;
        transition: all 0.2s ease-out;

        &:hover,
        &:focus,
        &:active {
            color: $color__alfa;
        }
    }
}

.section-social__alfa {
    display: flex;
    padding: 0 sizer(55) 0 sizer(70);
    position: relative;
    z-index: 3;
    position: absolute;
    bottom: sizer(70);
    left: 0;

    @include respond-to("-screen-xxxl") {
        bottom: sizer(55);
    }

    @include respond-to("-screen-xxl") {
        bottom: sizer(35);
    }

    @include respond-to("-screen-xl") {
        bottom: sizer(20);
        display: none;
    }

    .social {
        &__icon {
            width: sizer(34);
            height: sizer(34);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: sizer(1) solid $color__alfa;
        }
    }

    img {
        width: sizer(17);
        height: sizer(17);
    }
}

.section {
    &__content {
        &--alfa {
            padding: sizer(75) sizer(100) sizer(75) sizer(150);

            @include respond-to("-screen-xxxl") {
                padding: sizer(60) sizer(80) sizer(60) sizer(130);
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(50) sizer(50) sizer(50) sizer(100);
            }

            @include respond-to("-screen-xl") {
                padding: sizer(50) sizer(30) sizer(30) sizer(50);
            }

            @include respond-to("-screen-lg") {
                padding: sizer(40) sizer(25) sizer(25) sizer(40);
            }

            @include respond-to("-screen-md") {
                padding: sizer(30) sizer(20) sizer(20) sizer(30);
            }

            @include respond-to("-screen-sm") {
                padding: sizer(20) sizer(20) sizer(20) sizer(20);
            }
        }
    }

    &__bg {
        &--alfa {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;

            &,
            img {
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
            }
        }
    }
}

.container {
    &__alfa {
        max-width: calc(100% - #{sizer(200)});
        margin: 0 auto;

        @include respond-to("-screen-xxxl") {
            max-width: calc(100% - #{sizer(150)});
        }

        @include respond-to("-screen-xxl") {
            max-width: calc(100% - #{sizer(110)});
        }

        @include respond-to("-screen-xl") {
            max-width: calc(100% - #{sizer(90)});
        }

        @include respond-to("-screen-lg") {
            max-width: calc(100% - #{sizer(70)});
        }

        @include respond-to("-screen-md") {
            max-width: calc(100% - #{sizer(50)});
        }

        @include respond-to("-screen-sm") {
            max-width: calc(100% - #{sizer(40)});
        }
    }
}

.title-border {
    &__alfa {
        display: flex;
        flex-direction: column;

        &::after {
            content: "";
            display: block;
            width: sizer(75);
            height: sizer(6);
            background: $gradient__beta;
            margin: sizer(25) 0 sizer(35);
        }
    }
}

.list {
    &__alfa {
        ul {
            li {
                padding-left: sizer(20);
                position: relative;
                margin: 0 0 sizer(10);

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: "";
                    background: linear-gradient(162deg, $color__beta 1%, $color__gamma 100%);
                    display: block;
                    width: sizer(10);
                    height: sizer(10);
                    position: absolute;
                    left: 0;
                    top: sizer(5);
                }
            }
        }

        ol {
            counter-reset: listCounter;
            padding: 1em 0;

            li {
                padding-left: sizer(30);
                position: relative;
                margin: 0 0 sizer(10);

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    counter-increment: listCounter;
                    content: counter(listCounter) ".";
                    color: $color__beta;
                    font-weight: $weight__bold;
                    display: block;
                    // width: sizer(10);
                    // height: sizer(10);
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

.no-pseudo {
    &::after,
    &::before {
        content: none !important;
    }
}

.page-bg {
    z-index: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    mix-blend-mode: multiply;
    mix-blend-mode: darken;

    @include respond-to("-screen-md") {
        display: none;
    }

    .tsparticles {
        position: relative;

        @include respond-to("-screen-md") {
            display: none;
        }
    }

    canvas {
        width: 100% !important;

        @include respond-to("-screen-md") {
            display: none;
        }
    }
}

.section-bg {
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    background: linear-gradient(45deg, #1b77bd 0%, #2193cb 100%);
    mix-blend-mode: darken;
    opacity: 1;

    #ts2 {
        position: relative;
        height: 100%;

        @include respond-to("-screen-md") {
            display: none;
        }
    }

    canvas {
        position: relative;
        width: 100% !important;
    }
}

html {
    //****** styles for scrollbar ******//
    scrollbar-color: $color__scroll-thumb $color__scroll-track;
    scrollbar-width: thin;

    /* width */
    &::-webkit-scrollbar {
        width: initial;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color__scroll-track;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color__scroll-thumb;
        border-radius: sizer(10);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-hover__scroll-thumb;
    }
}
