//   __   __   ___      ___  ___  __      __
//  /  ` |__) |__   /\   |  |__  |  \    |__) \ /
//  \__, |  \ |___ /~~\  |  |___ |__/    |__)  |

// ** Bartłomiej Jedlikowski **
// LinkedIn: https://www.linkedin.com/in/jedlibartlomiej/
// Gmail: jedlikowskib@gmail.com
// -------------------------------------------------------------------

// Styles that involve fonts
// Like font weights, font styles, font colors

// Define default font and color
body {
    font-family: $font__alfa;
    color: $color__alfa;
    font-style: normal;
}

// Text classes
.text {
    &__alfa {
        font-size: text-sizer(80);
        line-height: 1.4em;

        @include respond-to("-screen-xxxl") {
            font-size: text-sizer(75);
        }

        @include respond-to("-screen-xxl") {
            font-size: text-sizer(70);
        }

        @include respond-to("-screen-xl") {
            font-size: text-sizer(55);
        }

        @include respond-to("-screen-lg") {
            font-size: text-sizer(45);
        }

        @include respond-to("-screen-md") {
            font-size: text-sizer(38);
        }

        @include respond-to("-screen-sm") {
            font-size: text-sizer(32);
        }

        @include respond-to("-screen-xs") {
            font-size: text-sizer(28);
        }
    }

    &__beta {
        font-size: text-sizer(18);
        line-height: 1.66em;

        @include respond-to("-screen-xxl") {
            font-size: text-sizer(16);
        }

        @include respond-to("-screen-lg") {
            font-size: text-sizer(14);
        }

        @include respond-to("-screen-sm") {
            font-size: text-sizer(12);
        }
    }

    &__gamma {
        font-size: text-sizer(14);
        line-height: 1.44em;
    }

    &__delta {
        font-size: text-sizer(26);
        line-height: 1.44em;

        @include respond-to("-screen-xxxl") {
            font-size: text-sizer(24);
        }

        @include respond-to("-screen-xxl") {
            font-size: text-sizer(22);
        }

        @include respond-to("-screen-xl") {
            font-size: text-sizer(20);
        }

        @include respond-to("-screen-lg") {
            font-size: text-sizer(18);
        }

        @include respond-to("-screen-md") {
            font-size: text-sizer(16);
        }
    }

    &__epsilon {
        font-size: text-sizer(40);
        line-height: 1.25em;

        @include respond-to("-screen-xxxl") {
            font-size: text-sizer(36);
        }

        @include respond-to("-screen-xxl") {
            font-size: text-sizer(32);
        }

        @include respond-to("-screen-xl") {
            font-size: text-sizer(28);
        }

        @include respond-to("-screen-lg") {
            font-size: text-sizer(25);
        }

        @include respond-to("-screen-md") {
            font-size: text-sizer(22);
        }

        @include respond-to("-screen-sm") {
            font-size: text-sizer(19);
        }
    }

    &__zeta {
        font-size: text-sizer(16);
        line-height: 1.375em;

        @include respond-to("-screen-xl") {
            font-size: text-sizer(14);
        }

        @include respond-to("-screen-md") {
            font-size: text-sizer(12);
        }
    }

    &__eta {
        font-size: text-sizer(36);
        line-height: 1.25em;

        @include respond-to("-screen-xxxl") {
            font-size: text-sizer(33);
        }

        @include respond-to("-screen-xxl") {
            font-size: text-sizer(30);
        }

        @include respond-to("-screen-xl") {
            font-size: text-sizer(27);
        }

        @include respond-to("-screen-lg") {
            font-size: text-sizer(24);
        }

        @include respond-to("-screen-md") {
            font-size: text-sizer(21);
        }

        @include respond-to("-screen-sm") {
            font-size: text-sizer(19);
        }
    }

    &__theta {
        font-size: text-sizer(40);
        line-height: 1.25em;

        @include respond-to("-screen-xxxl") {
            font-size: text-sizer(37);
        }

        @include respond-to("-screen-xxl") {
            font-size: text-sizer(33);
        }

        @include respond-to("-screen-xl") {
            font-size: text-sizer(30);
        }

        @include respond-to("-screen-lg") {
            font-size: text-sizer(28);
        }

        @include respond-to("-screen-md") {
            font-size: text-sizer(25);
        }

        @include respond-to("-screen-sm") {
            font-size: text-sizer(22);
        }
    }

    &__iota {
        font-size: text-sizer(20);
        line-height: 1.44em;
    }

    &__kappa {
        font-size: text-sizer(22);
        line-height: 1.44em;

        @include respond-to("-screen-xxxl") {
            font-size: text-sizer(20);
        }

        @include respond-to("-screen-xxl") {
            font-size: text-sizer(18);
        }

        @include respond-to("-screen-xl") {
            font-size: text-sizer(16);
        }

        @include respond-to("-screen-md") {
            font-size: text-sizer(14);
        }
    }

    &-h3 {
        &__beta {
            h3 {
                @extend .text__beta;
            }
        }
    }
}

.lh {
    &__alfa {
        line-height: 1.75em !important;
    }

    &__beta {
        line-height: 2em !important;
    }
}
.spacing {
    &__alfa {
        letter-spacing: sizer(-0.025);
    }
}

// Text colors
.color {
    &__alfa {
        color: $color__alfa;
    }

    &__beta {
        color: $color__beta;
    }

    &__psi {
        color: $color__psi;
    }

    &__omega {
        color: $color__omega;
    }

    &-strong {
        &__beta {
            strong {
                color: $color__beta;
            }
        }
    }

    &-h3 {
        &__beta {
            h3 {
                color: $color__beta;
            }
        }
    }
}

// Hover colors
.color-hover {
    transition: all 0.2s ease-in;

    &__alfa {
        &:hover,
        &:focus,
        &:active {
            color: $color__alfa;
        }
    }
}

// Font types (families)
.font {
    &__alfa {
        font-family: $font__alfa;
    }

    &__beta {
        font-family: $font__beta;
    }
}

// Font weights
// Generate classes for all weights defined in 'variables.scss'
.weight {
    @each $name, $weight in $weights {
        &__#{$name} {
            font-weight: #{$weight} !important;
        }

        &-strong__#{$name} {
            strong {
                font-weight: #{$weight} !important;
            }
        }

        &-h3__#{$name} {
            h3 {
                font-weight: #{$weight} !important;
            }
        }
    }
}
