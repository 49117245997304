@import "../imports";

.section-faq-home {
    .web-header__title {
        @include respond-to("-screen-xl") {
            position: relative;
            z-index: 20;
            margin: sizer(20);
            margin-left: sizer(50);

            &::before {
                content: none;
            }
        }
    }
    .section {
        &__nav {
            @include respond-to("-screen-xl") {
                width: 50%;

                & > div {
                    overflow: visible !important;
                    max-height: none !important;
                    margin-right: 0 !important;
                    margin-bottom: 0 !important;

                    & > div {
                        overflow: visible !important;
                        max-height: none !important;
                        margin-right: 0 !important;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        &__container {
            display: flex;

            @include respond-to("-screen-xl") {
                flex-direction: column;
            }
        }

        &__wrapper {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }

        &__content,
        &__header {
            position: relative;
            z-index: 3;
        }

        &__header {
            z-index: 10;
        }

        &__content {
            // width: sizer(1800);
            // display: flex;
            flex-grow: 1;

            @include respond-to("-screen-xl") {
                & > div {
                    overflow: visible !important;
                    max-height: none !important;
                    margin-right: 0 !important;
                    margin-bottom: 0 !important;
                    min-height: none !important;

                    & > div {
                        overflow: visible !important;
                        max-height: none !important;
                        margin-right: 0 !important;
                        margin-bottom: 0 !important;
                        min-height: none !important;
                    }
                }
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            .list {
                &__item {
                    width: 50vw;

                    @include respond-to("-screen-xl") {
                        width: 100%;
                    }

                    &:last-child {
                        .item {
                            &__border {
                                &::after {
                                    display: block !important;
                                }
                            }
                        }
                    }

                    .item {
                        &__bg {
                            position: absolute;
                            width: 100%;
                            left: 50%;
                            transform: translate(-50%);
                            height: 100%;
                            top: 0;
                            z-index: 2;

                            &::before,
                            &::after {
                                content: "";
                                position: absolute;
                                left: 50%;
                                transform: translate(-50%, 0%);
                                width: 70%;
                                height: 50%;
                                z-index: 1;
                                opacity: 0;
                                transition: all 0.3s ease-out;
                            }

                            &::before {
                                box-shadow: 0 sizer(-16) sizer(38) sizer(-16) rgba(0, 0, 0, 0.1) !important;
                                top: 0;
                            }

                            &::after {
                                box-shadow: 0 sizer(16) sizer(38) sizer(-16) rgba(0, 0, 0, 0.1) !important;
                                bottom: 0;
                            }
                        }

                        &__top,
                        &__content {
                            position: relative;
                            z-index: 5;
                        }

                        &__top {
                            display: flex;
                            text-align: left;
                            padding: sizer(30) 0 sizer(15);

                            .counter {
                                width: sizer(40);
                                flex: 0 0 sizer(40);
                                color: $color__beta;
                            }

                            .arrow {
                                margin-left: sizer(35);
                                transition: all 0.3s ease-out;
                                display: flex;
                                align-items: center;
                                opacity: 0;

                                &::before {
                                    content: "";
                                    display: block;
                                    width: sizer(9);
                                    height: sizer(9);
                                    border: sizer(3) solid $color__beta;
                                    border-left: none;
                                    border-top: none;
                                    transform: rotate(-45deg);
                                    transition: all 0.3s ease-out;
                                }
                            }

                            .text {
                                flex-grow: 1;
                            }
                        }

                        &__content {
                            display: none;
                            text-align: left;
                            padding: 0 sizer(40) sizer(40);
                        }

                        &__link {
                            width: 100%;
                            border: none;
                            background: transparent;
                            cursor: pointer;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                z-index: 3;
                                background: linear-gradient(
                                    to left,
                                    rgba($color__omega, 0.000001),
                                    $color__omega,
                                    rgba($color__omega, 0.000001)
                                );
                                width: 100%;
                                height: 100%;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                opacity: 0;
                                transition: all 0.3s ease-out;
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                .item {
                                    &__top {
                                        .arrow {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }

                            .item {
                                &__border {
                                    transition: all 0.3s ease-out;

                                    &::before,
                                    &::after {
                                        content: "";
                                        height: sizer(1);
                                        width: 100%;
                                        background: linear-gradient(
                                            to left,
                                            rgba($color__psi, 0.0000001),
                                            $color__psi,
                                            rgba($color__psi, 0.0000001)
                                        );
                                        display: block;
                                        position: absolute;
                                        left: 50%;
                                        transform: translate(-50%);
                                        opacity: 0.2;
                                    }

                                    &::before {
                                        top: 0;
                                    }

                                    &::after {
                                        bottom: 0;
                                        display: none;
                                    }
                                }
                            }

                            &.isActive {
                                &::before {
                                    opacity: 1;
                                }

                                .item {
                                    &__border {
                                        opacity: 0;
                                    }

                                    &__bg {
                                        &::before,
                                        &::after {
                                            opacity: 1;
                                        }
                                    }

                                    &__top {
                                        .arrow {
                                            opacity: 1;
                                        }
                                    }

                                    &__content {
                                        display: block;
                                        display: flex;
                                        flex-direction: column;

                                        &::before {
                                            content: "";
                                            display: block;
                                            width: sizer(75);
                                            height: sizer(6);
                                            background: $gradient__beta;
                                            margin: sizer(20) 0 sizer(40);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__title {
            display: flex;
            flex-direction: column;
            padding-left: sizer(40);
            position: relative;
            margin-bottom: sizer(17);
        }

        &__results {
            width: calc(40vw - #{sizer(150)});
            margin: 0 0 0 sizer(120);
            padding: sizer(30) 0 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include respond-to("-screen-xl") {
                width: 50%;
                margin-left: sizer(35);

                & > div {
                    overflow: visible !important;
                    max-height: none !important;
                    margin-right: 0 !important;
                    margin-bottom: 0 !important;

                    & > div {
                        overflow: visible !important;
                        max-height: none !important;
                        margin-right: 0 !important;
                        margin-bottom: 0 !important;
                    }
                }
            }

            .results {
                &__item {
                    display: none;

                    &.isActive {
                        display: block;
                    }
                }

                &__header {
                    margin: 0 0 sizer(25);
                }

                &__link {
                    color: $color__beta;
                    font-weight: $weight__medium;
                    font-size: text-sizer(14);
                    text-transform: uppercase;
                    margin: sizer(50) 0 0;

                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}
