@import "../imports";

.section-offer {
    .web-header__title {
        @include respond-to("-screen-xl") {
            position: relative;
            z-index: 20;
            margin: sizer(20);
            margin-left: 0;
            width: 100%;
            text-align: center;
            display: block;

            &::before {
                content: none;
            }
        }
    }

    .section {
        &__container {
            display: flex;
        }

        &__wrapper {
            padding-top: sizer(20);
            position: relative;
            overflow: hidden;
            // display: flex;
        }

        &__content,
        &__header {
            position: relative;
            z-index: 3;
        }

        &__header {
            z-index: 10;
        }

        &__content {
            // width: sizer(1480);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: sizer(15) sizer(190) sizer(0) sizer(150);
            height: calc(100% - #{sizer(120)});

            @include respond-to("-screen-xl") {
                padding: sizer(50) sizer(30) sizer(30) sizer(50);
            }

            @include respond-to("-screen-lg") {
                padding: sizer(40) sizer(25) sizer(25) sizer(40);
            }

            @include respond-to("-screen-md") {
                padding: sizer(30) sizer(20) sizer(20) sizer(30);
            }

            @include respond-to("-screen-sm") {
                padding: sizer(20) sizer(20) sizer(20) sizer(20);
            }
        }

        &__image {
            width: 67vh;

            @include respond-to("-screen-xl") {
                display: none;
            }

            &,
            img {
                height: 100%;
            }
        }

        &__list {
            display: grid;
            height: 100%;
            // flex-wrap: wrap;
            // width: sizer(2000);
            grid-template-rows: repeat(3, 33%);
            grid-auto-flow: column;
            grid-auto-columns: sizer(500);

            @include respond-to("-screen-xxxl") {
                grid-template-rows: repeat(2, auto);
                grid-auto-columns: sizer(450);
                margin-top: auto;
                margin-bottom: auto;
                align-self: center;
                justify-self: center;
            }

            @include respond-to("-screen-xl") {
                grid-template-columns: repeat(2, 1fr);
                grid-auto-flow: row;
                grid-auto-rows: auto;
            }

            @include respond-to("-screen-xs") {
                grid-template-columns: 1fr;
                grid-auto-flow: row;
                grid-auto-rows: auto;
            }

            .list {
                &__item {
                    // width: sizer(500);
                    // flex: 0 0 sizer(500);

                    .item {
                        &__wrapper {
                            position: relative;
                            z-index: 2;

                            &:hover,
                            &:focus,
                            &:active,
                            &:focus-within {
                                &::before,
                                &::after {
                                    opacity: 1;
                                }

                                .item {
                                    &__link {
                                        cursor: pointer;

                                        &::before {
                                            opacity: 1;
                                        }
                                    }

                                    &__more {
                                        opacity: 1;
                                    }
                                }
                            }

                            &::before,
                            &::after {
                                content: "";
                                position: absolute;
                                left: 50%;
                                transform: translate(-50%, 0%);
                                width: 70%;
                                height: 50%;
                                z-index: 1;
                                opacity: 0;
                                transition: all 0.3s ease-out;
                            }

                            &::before {
                                box-shadow: 0 sizer(-16) sizer(38) sizer(-16) rgba(0, 0, 0, 0.1) !important;
                                top: 0;
                            }

                            &::after {
                                box-shadow: 0 sizer(16) sizer(38) sizer(-16) rgba(0, 0, 0, 0.1) !important;
                                bottom: 0;
                            }
                        }

                        &__link {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            padding: sizer(10) sizer(30) sizer(20);
                            position: relative;
                            z-index: 3;
                            text-align: center;

                            @include respond-to("-screen-sm") {
                                padding: sizer(10) sizer(15) sizer(10);
                            }

                            @include respond-to("-screen-xs") {
                                padding-top: sizer(15);
                                padding-bottom: sizer(15);
                            }

                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background: $color__omega;
                                z-index: 2;
                                opacity: 0;
                                transition: all 0.3s ease-out;
                            }
                        }

                        &__image,
                        &__content {
                            position: relative;
                            z-index: 4;
                        }

                        &__text {
                            @include respond-to("screen-xl") {
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }
                        }

                        &__image {
                            img {
                                width: sizer(50);
                                height: sizer(50);
                                filter: brightness(0) saturate(100%) invert(63%) sepia(24%) saturate(6967%)
                                    hue-rotate(168deg) brightness(91%) contrast(84%);
                            }
                        }

                        &__header {
                            margin: 0 0 sizer(15);
                        }

                        &__content {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }

                        &__more {
                            line-height: 2.1em;
                            margin: sizer(10) 0 0 0;
                            opacity: 0;
                            transition: all 0.3s ease-out;

                            @include respond-to("-screen-md") {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}
