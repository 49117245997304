@import "../imports.scss";

.section-error {
    min-height: 100vh;
    width: 100vw;
    padding: 0 5.2%;
    display: flex;
    align-items: center;

    .col {
        &-logo {
            @include respond-to("-screen-lg") {
                order: 0;
            }
        }

        &-404 {
            @include respond-to("-screen-lg") {
                order: 1;
            }

            @include respond-to("-screen-md") {
                order: 2;
            }
        }

        &-text {
            @include respond-to("-screen-lg") {
                order: 1;
            }

            @include respond-to("-screen-md") {
                justify-content: center;
                text-align: center;
            }
        }
    }

    .error {
        &__bg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            display: block;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__logo {
            width: 100%;
            transform: translate(-10%);

            @include respond-to("-screen-xxl") {
                transform: translate(0);
                width: 110%;
            }

            @include respond-to("-screen-lg") {
                width: 22%;
                margin: 0 auto sizer(50);
            }

            @include respond-to("-screen-md") {
                width: 30%;
                margin-bottom: sizer(70);
                min-width: sizer(140);
            }

            img {
                width: 100%;
            }
        }

        &__404 {
            width: 100%;
            transform: translate(12%);
            position: relative;
            display: block;

            @include respond-to("-screen-xxl") {
                // transform: translate(0);
                width: 140%;
            }

            @include respond-to("-screen-lg") {
                transform: translate(-5%);
            }

            @include respond-to("-screen-md") {
                width: 60%;
                margin: 0 auto;
            }

            @include respond-to("-screen-sm") {
                width: 75%;
            }

            img {
                width: 100%;

                @include respond-to("-screen-lg") {
                    width: 120%;
                }
            }
        }

        &__text {
            line-height: 1.2em;
            letter-spacing: -0.1em;

            .text {
                &__top {
                    font-size: clamp(text-sizer(20), 2vw, text-sizer(40));
                    font-weight: $weight__bold;
                    margin-bottom: 0.7em;

                    @include respond-to("-screen-xxl") {
                        font-size: clamp(text-sizer(20), 2.2vw, text-sizer(40));
                    }
                }

                &__bottom {
                    font-size: clamp(text-sizer(16), 2vw, text-sizer(30));
                    font-weight: $weight__light;
                    margin-bottom: 1.1em;

                    @include respond-to("-screen-xxl") {
                        font-size: clamp(text-sizer(18), 2.2vw, text-sizer(30));
                    }
                }

                &__link {
                    color: $color__beta;
                    font-size: text-sizer(14);
                    letter-spacing: 0;
                    font-weight: $weight__bold;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    width: max-content;

                    @include respond-to("-screen-xl") {
                        font-size: text-sizer(12);
                    }

                    @include respond-to("-screen-md") {
                        margin: 0 auto;
                    }

                    &::after {
                        content: "";
                        display: block;
                        width: sizer(9);
                        height: sizer(9);
                        border: sizer(3) solid $color__beta;
                        border-left: none;
                        border-top: none;
                        transform: rotate(-45deg);
                        transition: all 0.3s ease-out;
                        margin-left: sizer(10);
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        &::after {
                            transform: rotate(-45deg) translate(sizer(10), sizer(10));
                        }
                    }
                }
            }
        }
    }
}
