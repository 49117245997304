@import "../imports";

.section-about-innovations {
    position: relative;

    .section {
        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__container {
            position: relative;
            z-index: 3;
            padding: sizer(80) 0;

            @include respond-to("-screen-xxl") {
                padding: sizer(70) 0;
            }

            @include respond-to("-screen-xl") {
                padding: sizer(60) 0;
            }

            @include respond-to("-screen-lg") {
                padding: sizer(50) 0;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            padding: sizer(40) sizer(160) sizer(0);
            text-align: center;
            margin-left: sizer(-70);

            @include respond-to("-screen-xxxl") {
                padding: sizer(40) sizer(130) sizer(0);
                margin-left: sizer(-60);
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(40) sizer(80) sizer(0);
                margin-left: sizer(-50);
            }

            @include respond-to("-screen-xl") {
                padding: sizer(30) sizer(40) sizer(0);
                margin-left: sizer(-35);
            }

            @include respond-to("-screen-xl") {
                margin-left: sizer(-50);
            }

            @include respond-to("-screen-md") {
                padding: sizer(20) sizer(30) sizer(0);
                margin-left: sizer(-30);
            }

            @include respond-to("-screen-sm") {
                margin-left: 0;
            }

            .list {
                &__item {
                    width: 33.3333%;
                    flex: 0 0 33.3333%;
                    padding: 0 0 0 sizer(70);

                    @include respond-to("-screen-xxxl") {
                        padding-left: sizer(60);
                    }

                    @include respond-to("-screen-xxl") {
                        padding-left: sizer(50);
                    }

                    @include respond-to("-screen-xl") {
                        padding-left: sizer(35);
                    }

                    @include respond-to("-screen-lg") {
                        width: 50%;
                        flex: 0 0 50%;
                        padding-left: sizer(50);
                        padding-top: sizer(45);
                    }

                    @include respond-to("-screen-md") {
                        padding-left: sizer(30);
                    }

                    @include respond-to("-screen-sm") {
                        width: 100%;
                        flex: 0 0 100%;
                        padding-left: 0;
                    }

                    .item {
                        &__header {
                            padding: 0 10%;
                        }

                        &__image {
                            img {
                                height: sizer(210);

                                @include respond-to("-screen-xxxl") {
                                    height: sizer(180);
                                }

                                @include respond-to("-screen-xxl") {
                                    height: sizer(160);
                                }

                                @include respond-to("-screen-xl") {
                                    height: sizer(140);
                                }

                                @include respond-to("-screen-lg") {
                                    height: sizer(120);
                                }

                                @include respond-to("-screen-md") {
                                    height: sizer(100);
                                }

                                @include respond-to("-screen-sm") {
                                    height: sizer(80);
                                }
                            }
                        }

                        &__header {
                            margin: 0 0 sizer(5);
                        }

                        &__more {
                            margin: sizer(15) 0 0;
                        }
                    }
                }
            }
        }
    }
}
