.web-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    // overflow: hidden;

    &.isSingle {
        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            height: 100%;
            transform: translate(-50%);
        }

        &::before {
            content: "";
            box-shadow: -4.596px 3.857px 38px 0px rgba(0, 0, 0, 0.1);
            width: 70%;
            transform: translate(-50%);
            z-index: 1;
        }

        &::after {
            z-index: 2;
            width: 100%;
            background: $color__omega;
        }

        .web-header {
            &__container {
                padding-top: sizer(15);
                padding-bottom: sizer(15);
            }
        }
    }

    &__container,
    &__nav {
        position: relative;
        z-index: 5;
    }

    &__container {
        padding: sizer(55) sizer(15) 0 sizer(20);
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-to("screen-sm") {
            padding: sizer(55) sizer(35) 0 sizer(40);
        }

        @include respond-to("screen-xl") {
            padding: sizer(55) sizer(45) 0 sizer(50);
        }

        @include respond-to("screen-xxl") {
            padding: sizer(55) sizer(55) 0 sizer(70);
        }
    }

    &__logo {
        position: relative;
        overflow: hidden;

        img {
            width: sizer(100);

            @include respond-to("screen-md") {
                width: sizer(115);
            }

            @include respond-to("screen-xl") {
                width: sizer(125);
            }
        }

        .alt {
            position: absolute;
            top: 100%;
        }
    }

    &__main {
        display: flex;
        align-items: center;
    }

    &__title {
        font-size: sizer(26);
        line-height: 1.4em;
        font-weight: $weight__bold;
        display: flex;

        &::before {
            content: "";
            display: block;
            width: sizer(1);
            background: $color__psi;
            height: sizer(38);
            margin: 0 sizer(60);
        }
    }

    &__burger {
        margin: 0 sizer(10) 0 sizer(25);
        width: sizer(20);
        height: sizer(20);
        position: relative;
        background: transparent;
        border: none;
        z-index: 100;
        background: transparent;
        border-radius: 50%;
        z-index: 2;
        cursor: pointer;

        @include respond-to("screen-sm") {
            width: sizer(30);
            height: sizer(25);
        }

        &.isActive {
            .burger__span {
                &:nth-of-type(1),
                &:nth-of-type(3) {
                    top: 50%;
                    transition: top 0.2s, transform 0.2s 0.2s;
                }

                &:nth-of-type(1) {
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:nth-of-type(2) {
                    opacity: 0;
                    transition: opacity 0.2s ease-in;
                }

                &:nth-of-type(3) {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        .burger__span {
            width: sizer(26);
            height: sizer(3);
            background: $color__alfa;
            left: 50%;
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);

            $spans-distance: sizer(5);

            &:nth-of-type(1) {
                top: calc(50% - #{$spans-distance});
                transform: translate(-50%, -100%);
            }

            &:nth-of-type(2) {
                top: 50%;
                transform: translate(-50%, -25%);
                transition: opacity 0.2s 0.2s ease-in;
            }

            &:nth-of-type(3) {
                top: calc(50% + #{$spans-distance});
                transform: translate(-50%, 50%);
            }

            &:nth-of-type(1),
            &:nth-of-type(3) {
                transition: transform 0.2s, top 0.2s 0.2s;
            }
        }
    }

    &__user {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        .text {
            color: $color__beta;
            list-height: 1em;
        }

        img {
            width: sizer(17);
            margin: 0 0 0 sizer(20);
        }
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__nav {
        position: absolute;
        top: 100%;
        right: sizer(20);
        background: $color__omega;
        box-shadow: 0 0 sizer(10) rgba($color__psi, 0.2);
        padding: sizer(25) sizer(50);
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease-out;

        &.isActive {
            opacity: 1;
            visibility: visible;
        }

        .nav {
            &__item {
                margin-bottom: sizer(10);

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__link {
                transition: all 0.2s ease-out;
                padding: 0;
                border: none;
                background: none;
                font-size: inherit;
                font-weight: inherit;
                cursor: pointer;

                &:hover,
                &:focus,
                &:active {
                    color: $color__beta;
                }
            }
        }
    }

    &__social {
        display: flex;
        position: relative;
        z-index: 3;
        margin: 0 0 0 sizer(10);

        .social {
            &__icon {
                width: sizer(34);
                height: sizer(34);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: sizer(1) solid $color__alfa;
            }
        }

        img {
            width: sizer(17);
            height: sizer(17);
        }
    }
}
