@import "../imports";
@import "../base/BaseTypography";

.section-our-innovations-hero {
    position: relative;
    &.scroll {
        padding-top: sizer(35);
    }
    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%);
    }

    &::before {
        content: "";
        box-shadow: -4.596px 3.857px 38px 0px rgba(0, 0, 0, 0.1);
        width: 70%;
        transform: translate(-50%);
        z-index: 1;
        height: 50%;
    }

    &::after {
        z-index: 2;
        width: 100%;
        background: $color__omega;
        height: 100%;
    }

    .section {
        &__policy {
            letter-spacing: 0;
            line-height: 1.75em;

            @include respond-to("-screen-md") {
                padding: 0 sizer(15);
            }

            h2 {
                display: flex;
                flex-direction: column;
                @extend .text__beta;
                font-weight: $weight__bold;
                letter-spacing: -0.025em;
                line-height: 1.2em;

                &::after {
                    content: "";
                    display: block;
                    width: sizer(75);
                    height: sizer(6);
                    background: $gradient__beta;
                    margin: sizer(25) 0 sizer(35);
                }

                strong {
                    display: inline;
                    color: $color__beta;
                    font-weight: $weight__bold;
                }
            }

            ul {
                margin-top: 1.5em;
                margin-bottom: 4em;

                li {
                    line-height: 1.93em;

                    &::before {
                        top: 0.55em;
                    }
                }
            }
        }

        &__content {
            padding-left: 8.33333%;

            @include respond-to("-screen-lg") {
                padding-left: 4%;
            }

            @include respond-to("-screen-md") {
                padding-left: 2%;
            }
        }

        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 3;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__container {
            position: relative;
            z-index: 5;
            padding-bottom: sizer(115);
            padding-top: 0;

            @include respond-to("-screen-xxxl") {
                padding-bottom: sizer(95);
            }

            @include respond-to("-screen-xxl") {
                padding-bottom: sizer(85);
            }

            @include respond-to("-screen-xl") {
                padding-bottom: sizer(75);
            }

            @include respond-to("-screen-lg") {
                padding-bottom: sizer(65);
            }

            @include respond-to("-screen-md") {
                padding-bottom: sizer(55);
            }

            @include respond-to("-screen-sm") {
                padding-bottom: sizer(40);
            }
        }

        &__image {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: sizer(40) 0 0;

            @include respond-to("-screen-md") {
                padding: 0;
                padding-top: sizer(50);
            }

            img {
                width: 153%;
                flex: 0 0 153%;
                transform: translate(5%);

                @include respond-to("-screen-md") {
                    width: 50%;
                    flex: none;
                    transform: translate(0);
                }

                @include respond-to("-screen-sm") {
                    width: 75%;
                }

                @include respond-to("-screen-xs") {
                    width: 90%;
                }
            }
        }

        &__header {
            padding: sizer(65) 0 sizer(60);
        }

        &__content {
        }

        &__subtitle {
            margin: 0 0 sizer(40);
        }

        &__prelude {
            margin: 0 0 sizer(30);
        }

        &__text {
            h3 {
                margin: 0 0 sizer(5);
            }
        }
    }
}
