// File for animations
// Define here all your keyframes

@keyframes show-opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-top {
    0% {
        opacity: 0;
        $transform: sizer(30);
        transform: translate(0, $transform);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes fade-right {
    0% {
        opacity: 0;
        transform: translate(-25%);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@keyframes fade-left {
    0% {
        opacity: 0;
        transform: translate(25%);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.animate {
    &__fade-top {
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    &__fade {
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    &__fade-right,
    &__fade-left {
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }
}

.animation-container {
    &.isVisible {
        .animate {
            &__fade-top {
                animation: fade-top 0.45s 0.3s ease-out forwards;
            }

            &__fade {
                animation: show-opacity 0.45s 0.3s ease-out forwards;
            }

            &__fade-right {
                animation: fade-right 0.45s 0.3s ease-out forwards;
            }

            &__fade-left {
                animation: fade-left 0.45s 0.3s ease-out forwards;
            }
        }
    }
}
