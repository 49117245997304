@import "../imports";

.section-guarantee {
    .section {
        &__container {
            display: flex;
        }

        &__wrapper {
            position: relative;
            overflow: hidden;
            display: flex;
            padding: sizer(100) sizer(305);

            @include respond-to("-screen-xxxl") {
                padding: sizer(85) sizer(270);
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(70) sizer(245);
            }

            @include respond-to("-screen-xl") {
                padding: sizer(110) sizer(75);
                padding-bottom: sizer(170);
            }

            @include respond-to("-screen-lg") {
                padding: sizer(90) sizer(55);
                padding-bottom: sizer(160);
            }

            @include respond-to("-screen-md") {
                padding: sizer(60) sizer(30);
                padding-bottom: sizer(160);
            }

            @include respond-to("-screen-sm") {
                padding: sizer(40) sizer(20);
            }
        }

        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__header {
            padding: sizer(50) sizer(60) 0;

            @include respond-to("-screen-md") {
                padding: sizer(20) sizer(15) 0;
            }

            @include respond-to("-screen-sm") {
                padding: sizer(25) 0;
            }

            z-index: 20;
        }

        &__content {
            position: relative;
            z-index: 3;
            color: $color__omega;
            text-align: center;
            display: flex;
            align-self: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: sizer(1150);
            border: sizer(1) solid $color__omega;
            border-bottom: none;

            @include respond-to("-screen-xxl") {
                width: sizer(1000);
            }

            @include respond-to("-screen-xl") {
                width: 100%;
            }

            @include respond-to("-screen-sm") {
                border: none;
            }
        }

        &__list {
            display: flex;
            align-items: center;
            // justify-content: center;
            width: 100%;
            transform: translate(0, 50%);

            @include respond-to("-screen-sm") {
                transform: translate(0);
            }

            .list {
                &__wrapper {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;

                    @include respond-to("-screen-lg") {
                        justify-content: space-around;
                    }

                    @include respond-to("-screen-sm") {
                        flex-wrap: wrap;
                    }

                    &::before,
                    &::after {
                        content: "";
                        width: 100%;
                        height: sizer(1);
                        background: $color__omega;
                        display: block;
                        width: sizer(200);

                        @include respond-to("-screen-xl") {
                            width: sizer(150);
                        }

                        @include respond-to("-screen-lg") {
                            width: sizer(80);
                        }

                        @include respond-to("-screen-md") {
                            width: 0;
                        }
                    }
                }

                &__item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-grow: 1;

                    @include respond-to("-screen-sm") {
                        width: 50%;
                        justify-content: center;
                    }

                    &::before,
                    &::after {
                        content: "";
                        width: 100%;
                        height: sizer(1);
                        background: $color__omega;
                        display: block;

                        @include respond-to("-screen-sm") {
                            content: none;
                        }
                    }

                    .item {
                        &__image {
                            width: sizer(80);
                            min-width: sizer(80);
                            height: sizer(80);
                            flex-shrink: none;
                            border: sizer(1) solid $color__omega;
                            border-radius: 50%;
                            // background: rgba($color__omega, 0.1);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 100%;
                                background-color: $color__omega;
                                opacity: 0.1;
                                transition: opacity 0.2s ease-out;
                            }

                            img {
                                height: sizer(40);
                                width: sizer(40);
                                object-fit: contain;
                                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
                                    hue-rotate(93deg) brightness(103%) contrast(103%);
                            }
                        }

                        &__wrapper {
                            position: relative;

                            @include respond-to("-screen-sm") {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                padding: 0 sizer(10);
                                padding-bottom: sizer(20);
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                .item__image {
                                    &::before {
                                        opacity: 0.4;
                                    }
                                }
                            }
                        }

                        &__text {
                            position: absolute;
                            top: calc(100% + #{sizer(20)});
                            left: 50%;
                            transform: translate(-50%);

                            @include respond-to("-screen-sm") {
                                position: relative;
                                transform: translate(0);
                                top: unset;
                                left: unset;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}
