// Rescale website for bigger screens
// SIZES
:root {
    --bw__sizer: 1px;
    --bw__text-sizer: 1rem;

    @include respond-to("screen-2k") {
        --bw__sizer: 1.5px;
        --bw__text-sizer: 1.5rem;
    }

    @include respond-to("screen-4k") {
        --bw__sizer: 2px;
        --bw__text-sizer: 2rem;
    }
}

body {
    background: $color__omega;
    margin: 0;
    overflow: hidden;
}

// Reset default styles

a,
a:hover,
a:focus,
a:active,
.btn,
.btn:focus,
.btn:active,
button,
button:focus,
button:active,
.form-control,
.form-control:active,
.form-control:focus {
    // outline: none !important;
    box-shadow: none !important;
    color: inherit;
    text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
address,
figure,
figcaption,
legend {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
    line-height: inherit;
}

ul,
ol {
    list-style: none;
}

ul,
ol,
li {
    padding: 0;
    margin: 0;
    padding-inline-start: 0;
}

label,
.form-group {
    margin: 0;
}

// Prevent transition before website is loaded
.preload *,
.preload *::before,
.preload *::after {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

// Prevent iPhone from zooming in on 'select'
select {
    @include respond-to("screen-md") {
        font-size: 16px;
    }
}

address {
    font-style: inherit;
}

button {
    font-family: inherit;
}
