@import "../imports";

.section-hero {
    @include respond-to("-screen-xl") {
        position: relative;
    }

    .section {
        &__container {
            display: flex;

            @include respond-to("-screen-xl") {
                width: 100%;
            }
        }

        &__wrapper {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: sizer(70);

            @include respond-to("-screen-xl") {
                padding-bottom: sizer(30);
            }
        }

        &__content,
        &__header {
            position: relative;
            z-index: 3;
        }

        &__content {
            width: sizer(1275);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include respond-to("screen-xl") {
                padding-top: sizer(30);
            }

            @include respond-to("-screen-xl") {
                width: 100%;
            }
        }

        &__header {
            margin: 0 0 sizer(10);
            z-index: 10;

            @include respond-to("-screen-xl") {
                width: 65%;
            }

            @include respond-to("-screen-md") {
                width: 100%;
                color: $color__omega;
                padding: sizer(165) 0;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            @include respond-to("-screen-sm") {
                padding: sizer(120) 0;
            }
        }

        &__text {
            width: 85%;

            @include respond-to("-screen-xl") {
                width: 65%;
                padding-right: sizer(15);
            }

            @include respond-to("-screen-md") {
                width: 100%;
                padding-right: 0;
            }
        }

        &__more {
            margin: sizer(35) 0 0;

            @include respond-to("-screen-xxxl") {
                margin: sizer(25) 0 0;
            }
        }

        &__image {
            padding: 0;
            position: relative;
            overflow: hidden;
            width: 100vh;

            @include respond-to("-screen-xl") {
                padding: 0;
                position: absolute;
                right: 0;
                top: 0;
                height: 70%;
                width: 35%;
                z-index: 100;
            }

            @include respond-to("-screen-md") {
                // display: none;
                width: 100%;
                height: sizer(400);
                right: 0;
                z-index: 2;
                background-color: rgba($color: $color__psi, $alpha: 0.5);
            }

            @include respond-to("-screen-sm") {
                height: sizer(300);
            }

            img {
                height: 100%;
                object-fit: cover;
                object-position: right;
                // position: absolute;

                // @include respond-to("-screen-xl") {
                //     height: auto;
                //     width: 100%;
                // }

                @include respond-to("-screen-md") {
                    width: 100%;
                    object-position: center;
                    mix-blend-mode: multiply;
                }
            }
        }

        &__gallery {
            margin: sizer(40) 0 0;

            @include respond-to("-screen-xxxl") {
                margin: sizer(35) 0 0;
            }

            @include respond-to("-screen-xxl") {
                margin: sizer(30) 0 0;
            }

            @include respond-to("-screen-xl") {
                margin-top: sizer(55);
            }

            @include respond-to("-screen-lg") {
                margin-top: sizer(48);
            }

            @include respond-to("-screen-md") {
                margin-top: sizer(38);
            }

            @include respond-to("-screen-sm") {
                margin-top: sizer(28);
            }

            .gallery {
                &__title {
                    margin: 0 0 sizer(25);

                    @include respond-to("-screen-xxxl") {
                        margin: 0 0 sizer(15);
                    }

                    &::after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: sizer(1);
                        background: $color__psi;
                        opacity: 0.2;
                        margin: sizer(7);
                    }
                }

                &__list {
                    position: relative;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        width: sizer(50);
                        height: 100%;
                        top: 0;
                        z-index: 5;
                    }

                    &::before {
                        left: sizer(-1);
                        background: linear-gradient(to right, $color__omega, rgba($color__omega, 0.00001));
                    }

                    &::after {
                        right: sizer(-1);
                        background: linear-gradient(to left, $color__omega, rgba($color__omega, 0.00001));
                    }
                }

                &__item {
                    padding: 0 sizer(30);

                    @include respond-to("-screen-md") {
                        padding: 0 sizer(20);
                    }

                    img {
                        height: sizer(40) !important;
                        width: auto !important;
                        filter: grayscale(1);
                        opacity: 0.3;

                        @include respond-to("-screen-lg") {
                            height: sizer(30) !important;
                        }

                        @include respond-to("-screen-md") {
                            height: sizer(25) !important;
                        }

                        @include respond-to("-screen-sm") {
                            height: sizer(20) !important;
                        }
                    }
                }
            }
        }
    }
}
