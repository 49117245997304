@import "../imports";

.section-about-article {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        height: 50%;
        transform: translate(-50%);
        box-shadow: 0 0 sizer(30) rgba($color__psi, 0.1);
        width: 100%;
        z-index: 1;
    }

    .section {
        &__container {
            padding: sizer(105) 20% sizer(110);

            @include respond-to("-screen-xxxl") {
                padding: sizer(90) 15% sizer(95);
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(80) 10% sizer(85);
            }

            @include respond-to("-screen-lg") {
                padding: sizer(70) sizer(35) sizer(75);
            }

            @include respond-to("-screen-md") {
                padding: sizer(60) sizer(30) sizer(65);
            }

            @include respond-to("-screen-sm") {
                padding: sizer(40) sizer(20) sizer(45);
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%);
            }

            &::before {
                content: "";
                box-shadow: -4.596px 3.857px 38px 0px rgba(0, 0, 0, 0.1);
                width: 70%;
                transform: translate(-50%);
                z-index: 1;
                height: 50%;
            }

            &::after {
                z-index: 2;
                width: 100%;
                background: $color__omega;
                height: 100%;
            }
        }

        &__header {
            margin-bottom: sizer(35);
        }

        &__header,
        &__text {
            position: relative;
            z-index: 4;
        }
    }
}
