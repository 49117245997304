//   __   __   ___      ___  ___  __      __
//  /  ` |__) |__   /\   |  |__  |  \    |__) \ /
//  \__, |  \ |___ /~~\  |  |___ |__/    |__)  |

// ** Bartłomiej Jedlikowski **
// LinkedIn: https://www.linkedin.com/in/jedlibartlomiej/
// Gmail: jedlikowskib@gmail.com
// -------------------------------------------------------------------

// ## THERE IS GREEK ALPHABET AT BOTTOM OF THIS FILE TO CHECK IF DO NOT KNOW HOW TO NAME YOUR VARIABLE/CLASS ##

// PATHS TO FILES
// To use when linking to image from scss
$path__img: "../img/";
// Example:
// background-image: url("#{$path-img}NameOfImage");

// FONTS:
$font__alfa: "Montserrat";
$font__beta: "Sansation";

// COLORS:
// Custom colors starting from Alfa
$color__alfa: #474648;
$color__beta: #249dd7;
$color__gamma: #1c78be;

// Last two letters of greek alphabet are opposite, basic colors (black and white) by default
$color__psi: #000;
$color__omega: #fff;

$gradient__alfa: linear-gradient(138deg, $color__gamma 1%, $color__beta 100%);
$gradient__beta: linear-gradient(to left, $color__beta, $color__gamma);

$color__scroll-thumb: #249dd7;
$color-hover__scroll-thumb: darken(#249dd7, 15%);
$color__scroll-track: #fff;

// Define which color will be used to differentiate important elements
// like error messages. Something similar bootstrap's 'Button danger'
// Usually color important is bright red
$color__important: #da0000;

// FONT WEIGHTS
$weight__thin: 100;
$weight__extra-light: 200;
$weight__light: 300;
$weight__regular: 400;
$weight__medium: 500;
$weight__semi-bold: 600;
$weight__bold: 700;
$weight__extra-bold: 800;
$weight__black: 900;

// Weights as array for 'each' loop in base/typography.scss
$weights: (
    "thin": $weight__thin,
    "extra-light": $weight__extra-light,
    "light": $weight__light,
    "regular": $weight__regular,
    "medium": $weight__medium,
    "semi-bold": $weight__semi-bold,
    "bold": $weight__bold,
    "extra-bold": $weight__extra-bold,
    "black": $weight__black,
);

// SCREEN SIZES:
$breakpoints: (
    // Breakpoint "Min width"
    "screen-4k":
        (
            min-width: 3112px,
        ),
    "screen-2k": (
        min-width: 2560px,
    ),
    "screen-fhd": (
        min-width: 1920px,
    ),
    "screen-xxxl": (
        min-width: 1600px,
    ),
    "screen-xxl": (
        min-width: 1366px,
    ),
    "screen-xl": (
        min-width: 1200px,
    ),
    "screen-lg": (
        min-width: 992px,
    ),
    "screen-md": (
        min-width: 768px,
    ),
    "screen-sm": (
        min-width: 576px,
    ),
    "screen-xs": (
        min-width: 468px,
    ),
    "screen-xxs": (
        min-width: 360px,
    ),
    // Breakpoint "Max width"
    "-screen-4k":
        (
            max-width: 3111px,
        ),
    "-screen-2k": (
        max-width: 2559px,
    ),
    "-screen-fhd": (
        max-width: 1919px,
    ),
    "-screen-xxxl": (
        max-width: 1599px,
    ),
    "-screen-xxl": (
        max-width: 1365px,
    ),
    "-screen-xl": (
        max-width: 1199px,
    ),
    "-screen-lg": (
        max-width: 991px,
    ),
    "-screen-md": (
        max-width: 767px,
    ),
    "-screen-sm": (
        max-width: 575px,
    ),
    "-screen-xs": (
        max-width: 467px,
    ),
    "-screen-xxs": (
        max-width: 359px,
    )
);

// Breakpoints for which will be generated custom bootstrap classes
// First is name of breakpoint
// Second is if should use 'max-width' or' min-width'
// ?HOW TO USE?
// Define 'screen' for breakpoints bigger than 1200px (bootstrap xl breakpoint)
// Defin '-screen' for breakpoints smaller than 576px (bootstrap sm breakpoint)

// **Remember to put sizes in order from smallest to biggest**
$breakpoints-bootstrap: (
    "xs": "-screen",
    "xxl": "screen",
    "xxxl": "screen",
);

// Breakpoints for which will be generated classes missing in default bootstrap
// (for example: offset right)

// **Remember to put sizes in order from smallest to biggest**
$breakpoints-bootstrap-default: (
    "xs": "-screen",
    "sm": "screen",
    "md": "screen",
    "lg": "screen",
    "xl": "screen",
    "xxl": "screen",
    "xxxl": "screen",
);

// ## GREEK ALPHABET: ##
// - alfa
// - beta
// - gamma
// - delta
// - epsilon
// - zeta
// - eta
// - theta
// - iota
// - kappa
// - lambda
// - mu
// - nu
// - xi
// - omicron
// - pi
// - rho
// - sigma
// - tau
// - upsilon
// - phi
// - omega
