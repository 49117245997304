@import "../imports";

.section-our-innovations-article1 {
    position: relative;
    z-index: 10;

    &.scroll {
        padding-top: sizer(35);
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%);
    }

    &::before {
        content: "";
        box-shadow: -4.596px 3.857px 38px 0px rgba(0, 0, 0, 0.1);
        width: 70%;
        transform: translate(-50%);
        z-index: 1;
        height: 50%;
    }

    &::after {
        z-index: 2;
        width: 100%;
        background: $color__omega;
        height: 70%;
    }

    .section {
        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 3;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__container {
            position: relative;
            z-index: 5;
            padding: sizer(115) 0;

            @include respond-to("-screen-xxxl") {
                padding: sizer(95) 0;
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(85) 0;
            }

            @include respond-to("-screen-xl") {
                padding: sizer(75) 0;
            }

            @include respond-to("-screen-lg") {
                padding: sizer(65) 0;
            }

            @include respond-to("-screen-md") {
                padding: sizer(55) 0;
            }

            @include respond-to("-screen-sm") {
                padding: sizer(40) 0;
            }
        }

        &__image {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: sizer(40) 0 0;

            img {
                width: 95%;
                flex: 0 0 95%;
                transform: translate(-2%);

                @include respond-to("-screen-lg") {
                    width: 130%;
                    transform: translate(-10%);
                }

                @include respond-to("-screen-md") {
                    width: 60%;
                    flex: none;
                    transform: translate(0);
                }

                @include respond-to("-screen-sm") {
                    width: 80%;
                }

                @include respond-to("-screen-xs") {
                    width: 95%;
                }
            }
        }

        &__header {
            padding: sizer(65) 0 sizer(60);
        }

        &__content {
            padding-right: 7%;
        }

        &__subtitle {
            margin: 0 0 sizer(40);
        }

        &__prelude {
            margin: 0 0 sizer(30);
        }

        &__text {
            h3 {
                margin: 0 0 sizer(5);
            }
        }
    }
}
