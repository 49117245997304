@import "../imports";

.section-about-hero {
    position: relative;

    .section {
        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;

            &,
            img {
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
                object-position: center;
            }
        }

        &__container {
            position: relative;
            z-index: 3;
            padding: sizer(100) 5%;

            @include respond-to("-screen-xxl") {
                padding: sizer(70) sizer(60);
            }

            @include respond-to("-screen-xl") {
                padding: sizer(50) sizer(50);
            }

            @include respond-to("-screen-lg") {
                padding: sizer(40) sizer(40);
            }

            @include respond-to("-screen-md") {
                padding: sizer(40) sizer(30);
            }

            @include respond-to("-screen-sm") {
                padding: sizer(40) sizer(20);
            }
        }

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;

            &::after {
                content: "";
                display: block;
                width: sizer(75);
                height: sizer(6);
                background: $gradient__beta;
                margin: sizer(30) 0 sizer(40);
            }
        }

        &__text {
            padding: sizer(40) 17% 0;

            @include respond-to("-screen-lg") {
                padding: sizer(30) 12% 0;
            }

            @include respond-to("-screen-md") {
                padding: sizer(20) 6% 0;
            }

            @include respond-to("-screen-xs") {
                padding: sizer(20) 0 0;
            }
        }
    }
}
