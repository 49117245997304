@import "../imports";

.section-our-innovations-article2 {
    position: relative;
    &.scroll {
        padding-top: sizer(35);
    }

    .section {
        &__bg {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 3;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left 0;
            }
        }

        &__container {
            position: relative;
            z-index: 5;
            padding-bottom: sizer(115);
            padding-top: sizer(115);

            @include respond-to("-screen-xxxl") {
                padding: sizer(95) 0;
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(85) 0;
            }

            @include respond-to("-screen-xl") {
                padding: sizer(75) 0;
            }

            @include respond-to("-screen-lg") {
                padding: sizer(65) 0;
            }

            @include respond-to("-screen-md") {
                padding: sizer(55) 0;
            }

            @include respond-to("-screen-sm") {
                padding: sizer(40) 0;
            }
        }

        &__image {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: sizer(40) 0 0;

            img {
                width: 153%;
                flex: 0 0 153%;
                transform: translate(5%);

                @include respond-to("-screen-md") {
                    width: 60%;
                    flex: none;
                    transform: translate(0);
                }

                @include respond-to("-screen-sm") {
                    width: 80%;
                }

                @include respond-to("-screen-xs") {
                    width: 95%;
                }
            }
        }

        &__header {
            padding: sizer(65) 0 sizer(60);
        }

        &__content {
            padding-right: 5%;
        }

        &__subtitle {
            margin: 0 0 sizer(40);
            padding: 0 25% 0 0;
        }

        &__prelude {
            margin: 0 0 sizer(30);
        }

        &__text {
            h3 {
                margin: 0 0 sizer(5);
            }
        }
    }
}
