@import "../imports";

.section-faq {
    position: relative;

    .section {
        &__container {
            padding: sizer(85) sizer(100) sizer(95);
            position: relative;
            z-index: 5;

            @include respond-to("-screen-xxxl") {
                padding: sizer(70) sizer(80) sizer(75);
            }

            @include respond-to("-screen-xxl") {
                padding: sizer(60) sizer(70) sizer(65);
            }

            @include respond-to("-screen-xl") {
                padding: sizer(50) sizer(55) sizer(50);
            }

            @include respond-to("-screen-lg") {
                padding: sizer(40) sizer(40) sizer(35);
            }

            @include respond-to("-screen-md") {
                padding: sizer(30) sizer(30) sizer(25);
            }

            @include respond-to("-screen-sm") {
                padding: sizer(20) sizer(20) sizer(20);
            }
        }

        &__header {
            margin: 0 0 sizer(50);

            @include respond-to("-screen-lg") {
                margin-bottom: sizer(40);
            }

            @include respond-to("-screen-md") {
                margin-bottom: sizer(30);
            }

            @include respond-to("-screen-sm") {
                margin-bottom: sizer(30);
            }

            &.home {
                color: #474648;
            }
        }

        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 3;

            img {
                width: 100%;
                height: auto !important;
            }
        }

        &__content {
            display: flex;
            justify-content: center;
        }

        &__list {
            width: 66.6666%;

            @include respond-to("-screen-xxxl") {
                width: 80%;
            }

            @include respond-to("-screen-xl") {
                width: 90%;
            }

            @include respond-to("-screen-lg") {
                width: 100%;
            }
        }

        &__item {
            .item {
                &__bg {
                    position: absolute;
                    width: 100%;
                    left: 50%;
                    transform: translate(-50%);
                    height: 100%;
                    top: 0;
                    z-index: 2;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 0%);
                        width: calc(100% + #{sizer(20)});
                        height: 50%;
                        z-index: 1;
                        opacity: 0;
                        transition: all 0.3s ease-out;
                    }

                    &::before {
                        box-shadow: 0 sizer(-16) sizer(38) sizer(-16) rgba(0, 0, 0, 0.1) !important;
                        top: 0;
                    }

                    &::after {
                        box-shadow: 0 sizer(16) sizer(38) sizer(-16) rgba(0, 0, 0, 0.1) !important;
                        bottom: 0;
                    }
                }

                &__top,
                &__content {
                    position: relative;
                    z-index: 5;
                }

                &__top {
                    display: flex;
                    text-align: left;
                    padding: sizer(30) 0 sizer(15);

                    @include respond-to("-screen-lg") {
                        padding: sizer(20);
                    }

                    @include respond-to("-screen-md") {
                        padding: sizer(15);
                    }

                    @include respond-to("-screen-sm") {
                        padding: sizer(10);
                    }

                    .counter {
                        width: sizer(40);
                        flex: 0 0 sizer(40);
                        color: $color__beta;

                        @include respond-to("-screen-sm") {
                            width: sizer(30);
                            flex: 0 0 sizer(30);
                        }

                        @include respond-to("-screen-xs") {
                            width: sizer(20);
                            flex: 0 0 sizer(20);
                        }
                    }

                    .arrow {
                        margin-left: sizer(35);
                        transition: all 0.3s ease-out;

                        @include respond-to("-screen-sm") {
                            margin-left: sizer(20);
                        }

                        &::before {
                            content: "";
                            display: block;
                            width: sizer(11);
                            height: sizer(11);
                            border: sizer(5) solid $color__beta;
                            border-left: none;
                            border-top: none;
                            transform: rotate(45deg);
                            transition: all 0.3s ease-out;
                        }
                    }

                    .text {
                        flex-grow: 1;
                    }
                }

                &__content {
                    display: none;
                    text-align: left;
                    padding: 0 sizer(40) sizer(40);

                    @include respond-to("-screen-xs") {
                        padding: 0 sizer(20) sizer(20);
                    }
                }

                &__link {
                    width: 100%;
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: 3;
                        background: linear-gradient(
                            to left,
                            rgba($color__omega, 0.000001),
                            $color__omega,
                            rgba($color__omega, 0.000001)
                        );
                        width: 100%;
                        height: 100%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        transition: all 0.3s ease-out;
                    }

                    &.isActive {
                        &::before {
                            opacity: 1;
                        }

                        .item {
                            &__bg {
                                &::before,
                                &::after {
                                    opacity: 1;
                                }
                            }

                            &__top {
                                .text {
                                    font-weight: $weight__bold;
                                }

                                .arrow {
                                    transform: translate(0, 20%);

                                    &::before {
                                        transform: rotate(45deg) scale(-1);
                                    }
                                }
                            }

                            &__content {
                                display: block;
                                display: flex;
                                flex-direction: column;

                                &::before {
                                    content: "";
                                    display: block;
                                    width: sizer(75);
                                    height: sizer(6);
                                    background: $gradient__beta;
                                    margin: sizer(20) 0 sizer(40);

                                    @include respond-to("-screen-sm") {
                                        margin: sizer(20) 0 sizer(25);
                                    }
                                }
                            }
                        }
                    }

                    .link {
                        &__button {
                            border: none;
                            background: transparent;
                            cursor: pointer;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
